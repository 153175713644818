import React from "react";

const Footer: React.FC = () => {
	function ToTop() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	window.addEventListener('scroll', () => {
		const totopWrap = document.getElementById('totop-wrap');
		const scrollWrap = document.getElementById('scroll-wrap')
		const totopBtn = document.getElementById('totop');
		const rect = totopWrap?.getBoundingClientRect();
		const position = rect?.top;
		if (window.scrollY > 300) {
			totopBtn?.classList.add('is_show');
		} else {
			totopBtn?.classList.remove('is_show');
		}
		if (window.innerWidth > 768) {
			if (position && position - window.innerHeight < -107) {
				scrollWrap?.classList.add('is_nofix');
			} else {
				scrollWrap?.classList.remove('is_nofix');
			}
		}
	});

	return (
		<>
			<div className="contents-wrap wrap__contact">
				<h2>
					<span className="h2--upper">何から始めればよいのか<br className="sp-only" />わからない初心者のお客様も、</span>
					<span className="h2--lower">専門的な相談をしたい<br className="sp-only" />お客様も、お気軽にご相談ください</span>
				</h2>
				<p>経験豊富なスペシャリストが丁寧に<br className="sp-only" />伺います</p>
				<a className="btn" href="https://www.nri-net.com/contact/cloud-solution/" target="_blank">
					<span className="btn__name">お問い合わせ</span>
					<div className="btn__arrow">
						<img src="/common/arrow_white.png" />
						<img src="/common/arrow_color.png" />
					</div>
				</a>
			</div>
			<div className="contents-wrap">
				<div className="footer-nav">
					<ul className="nav-list">
						<li className="nav-item"><a href="https://www.nri-net.com/company/" target="_blank" className="nav__menu">会社情報</a></li>
						<li className="nav-item"><a href="https://www.nri-net.com/disclaimer/" target="_blank" className="nav__menu">免責事項</a></li>
						<li className="nav-item"><a href="https://www.nri-net.com/site-policy/" target="_blank" className="nav__menu">サイト利用規定</a></li>
						<li className="nav-item"><a href="https://www.nri-net.com/privacy-policy/" target="_blank" className="nav__menu">個人情報保護方針</a></li>
						<li className="nav-item"><a href="https://www.nri-net.com/privacy/" target="_blank" className="nav__menu">個人情報の取扱いについて</a></li>
					</ul>
					<div className="btn-wrap" id="totop-wrap">
						<div className="btn-wrap--scroll" id="scroll-wrap">
							<button className="btn__totop" onClick={ToTop} type="button" id="totop">
								<div className="arrow__top">
									<img src="/common/arrow_totop_white.png" alt="ページトップへ" />
									<img src="/common/arrow_totop.png" alt="ページトップへ" />
								</div>
							</button>
						</div>
					</div>
					<a href="https://www.nri-net.com/" target="_blank">
						<img className="logo" src="/common/footer_logo.png" alt="NRI Netcom" />
					</a>
					<ul className="icon-link-list">
						<li className="icon-link-item">
							<a href="https://tech.nri-net.com/" target="_blank" className="icon-link">
								<img src="/common/logo_blog.png" alt="NRI Netcom BLOG" />
							</a>
						</li>
						<li className="icon-link-item">
							<a href="https://www.youtube.com/channel/UChnBMhqqaC7lGLFM4pOkNeA" target="_blank" className="icon-link">
								<img src="/common/logo_youtube.png" alt="NRI Netcom Official" />
							</a>
						</li>
					</ul>
					<span className="footer__copyright">© NRI Netcom, Ltd. All rights reserved.</span>
				</div>
			</div>
		</>
	);
};

export default Footer;