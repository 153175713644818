import React, { useRef, useState } from "react";
import { Link } from "gatsby";

type HeaderProps = {
	isFhNavOpen: boolean,
	setIsFhNavOpen: React.Dispatch<React.SetStateAction<boolean>>,
	isFixedHeader: boolean
}


const Header = (props: HeaderProps) => {
	const [isGhNavOpen, setIsGhNavOpen] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);

	// ナビゲーション表示非表示
	function OpenNavToggle(isFixedHeader: boolean) {
		// SPの場合
		if (window.innerWidth < 769) {
			// ナビゲーション表示している場合はbodyのスクロールを不可にする
			if (isFixedHeader) {
				switchScroll(props.isFhNavOpen);
				props.setIsFhNavOpen(!props.isFhNavOpen);
			} else {
				switchScroll(isGhNavOpen);
				setIsGhNavOpen(!isGhNavOpen);
			}
		}
	}

	function switchScroll(isScroll: boolean) {
		if (isScroll) {
			document.body.style.removeProperty('position');
			document.body.style.removeProperty('top');
			document.documentElement.scrollTop = scrollPosition;
		} else {
			const position = window.scrollY;
			setScrollPosition(position);
			document.body.style.position = 'fixed';
			document.body.style.top = `-${position}px`;
		}
	}

	return (
		<>
			<Link to="/" className="header--left">
				<img className="logo" src="/common/header_logo.png" alt="NRI Netcom" />
				<div className="header-text_wrap">
					<span className="text__ja pc-only">クラウドソリューション</span>
					<img className="text-img__ja sp-only" src="/common/logo_cloudsolution_sp.png" alt="クラウドソリューション" />
					<span className="text__en">Cloud Solution</span>
				</div>
			</Link>
			<div className={`overlay__gh sp-only${isGhNavOpen ? ` is-show` : ``}`}></div>
			<div className="header--right">
				<button className="btn__header-nav sp-only" type="button" onClick={() => { OpenNavToggle(props.isFixedHeader) }}></button>
				<div className={`nav-block${props.isFhNavOpen ? ` open-nav__fn` : ``}${isGhNavOpen ? ` open-nav__gn` : ``}`}>
					<button className="btn__close sp-only" type="button" onClick={() => { OpenNavToggle(props.isFixedHeader) }}>
						<div className="mark__plus"></div>
					</button>
					<ul className="nav-list">
						<li className="nav-item"><Link to="/feature/" className="nav__menu" onClick={() => { OpenNavToggle(props.isFixedHeader) }}>NRIネットコムの特長</Link></li>
						<li className="nav-item"><Link to="/solution/" className="nav__menu" onClick={() => { OpenNavToggle(props.isFixedHeader) }}>ソリューション</Link></li>
						<li className="nav-item"><Link to="/cases/" className="nav__menu" onClick={() => { OpenNavToggle(props.isFixedHeader) }}>実績紹介</Link></li>
						<li className="nav-item"><Link to="/topics/" className="nav__menu" onClick={() => { OpenNavToggle(props.isFixedHeader) }}>トピックス</Link></li>
						<li className="nav-item"><Link to="/reports/" className="nav__menu" onClick={() => { OpenNavToggle(props.isFixedHeader) }}>調査・レポート</Link></li>
						<li className="nav-item"><a href="https://www.nri-net.com/contact/cloud-solution/" target="_blank" className="nav__menu"onClick={() => { OpenNavToggle(props.isFixedHeader) }}>お問い合わせ</a></li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default Header;