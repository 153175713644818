import React, { createContext, useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import Header from "./Header";
import Footer from "./Footer";
import * as topStyles from "./../styles/page/top.module.scss";

export interface LayoutProps {
	children: any;
}

export const Context = createContext(true);

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
	const { children } = props;
	const location = useLocation();
	const pageCategory = location.pathname.split('/')[1];
	const isTop = !pageCategory;
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);
	}, []);

	const fhRef = useRef<HTMLDivElement>(null);
	const [isNavOpen, setIsNavOpen] = useState(false);

	window.addEventListener('scroll', () => {
		if (window.scrollY > 300 || isNavOpen) {
			fhRef?.current?.classList.add('is-show');
		} else {
			fhRef?.current?.classList.remove('is-show');
		}
	});

	return (
		<>
			{!loading && (
				<>
					<div className={`overlay__fh sp-only${isNavOpen ? ` is-show` : ``}`}></div>
					<header id="global__header" className={isTop ? "top-header" : ""}>
						<Header isFhNavOpen={isNavOpen} setIsFhNavOpen={setIsNavOpen} isFixedHeader={false}></Header>
					</header>
					<div ref={fhRef} id="fixed__header">
						<Header isFhNavOpen={isNavOpen} setIsFhNavOpen={setIsNavOpen} isFixedHeader={true}></Header>
					</div>
					<Context.Provider value={loading}>
						<main className={isTop ? `${topStyles.top_main}` : `${pageCategory}_main`}>{children}</main>
					</Context.Provider>
					<footer id="global__footer">
						<Footer></Footer>
					</footer>
				</>
			)}
		</>
	);
}

export default Layout;